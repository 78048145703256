import React, { useState } from 'react';
import './QueryForm.css'; // Make sure the CSS file is correctly referenced

function QueryForm() {
    const [formData, setFormData] = useState({
        parentsName: '',
        mobileNumber: '',
        preferredSport: '',
        preferredlocation: '',
        qresponse:'',
    });

    const sportLocations = {
        Badminton: [
            "Machaxi Badminton Centre - Munnekollal",
            "Machaxi Sumukha - Varthur",
            "Machaxi Aj Sports - Bellandur",
            "Machaxi J Sports - Hoodi",
            "Machaxi Ahalya - Near Manyata Tech Park",
            "Machaxi Play9 - Whitefield",
            "Machaxi Cross Court - Ramamurthy Nagar",
            "Machaxi Maagniv - Horamavu",
            "Machaxi Pragathi - Horamavu",
            "Machaxi Sportexx - Sahakar Nagar",
            "Machaxi Ready to Rally - Kasturi Nagar",
            "Machaxi Let's Play - Akshay Nagar",
        ],
        Swimming: [
            "Machaxi Ahalya - Near Manyata Tech Park",
            "Machaxi Sportexx - Sahakar Nagar",
            "Machaxi Aj Sports - Hoodi",
            "Machaxi Milan Sports - G B Palya",
            // "Machaxi Ready to Rally - Kasturi Nagar",
        ],
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://admin.machaxi.com/summer-api/query', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            alert(result.message); // Display success message from server
            // Optionally reset form data here if needed
            setFormData({
                parentsName: '',
                mobileNumber: '',
                preferredSport: '',
                preferredlocation: '',
                qresponse:'',
            });
        } catch (error) {
            console.error('Failed to submit the query:', error);
            alert('Failed to submit the query. Please try again.');
        }
    };

    return (
        <div className="query-container">
            <section className="benefits-section">
                <h2>Summer Camp 2024 Benefits</h2>
                <h3 style={{color:"blue", textAlign:"center"}}>Certificate signed by Nisha Millet for swimming players</h3>
                <div className="benefits-grid">
                    <div className="benefit-item">
                        <img src="/PrC.png" alt="Benefit B description"/> Professional Coaches
                    </div>
                    <div className="benefit-item">
                        <img src="/MPB.png" alt="Benefit C description"/> Multiple Plans & Batches
                    </div>
                    <div className="benefit-item">
                        <img src="/IT.png" alt="Benefit D description"/> Internal Tournament
                    </div>
                    <div className="benefit-item">
                        <img src="/PC.png" alt="Benefit E description"/> Participation Certificate
                    </div>
                    <div className="benefit-item">
                        <img src="/FG.png" alt="Benefit F description"/> Fun Games
                    </div>
                    <div className="benefit-item">
                        <img src="/CBA.png" alt="Benefit A description"/> Curriculum Based Approach
                    </div>
                </div>
            </section>

            <section className="query-section">
            <h2>Have Queries?</h2>
                <p style={{fontSize: "15px"}}>Submit the form below, and we will reach out to you.</p>
                <form className="query-form" onSubmit={handleSubmit}>
                    <h4 className="decrease-tag">Player's Name</h4>
                    <input type="text" name="parentsName" placeholder="Enter player's name" value={formData.parentsName}
                           onChange={handleChange} required/>

                    <h4 className="decrease-tag">Mobile Number</h4>
                    <input type="tel" name="mobileNumber" placeholder="Enter mobile number"
                           value={formData.mobileNumber} onChange={handleChange} required pattern="\d*"
                           title="Mobile number should contain digits only."/>

                    <h4 className="decrease-tag">Preferred Sport</h4>
                    <select name="preferredSport" value={formData.preferredSport} onChange={handleChange} required>
                        <option value="">Select a sport</option>
                        <option value="Badminton">Badminton</option>
                        <option value="Swimming">Swimming</option>
                        {/*<option value="Tennis">Tennis</option>*/}
                    </select>

                    <h4 className="decrease-tag">Preferred Location</h4>
                    <select name="preferredlocation" value={formData.preferredlocation} onChange={handleChange}
                            required>
                        <option value="">Select a location</option>
                        {formData.preferredSport && sportLocations[formData.preferredSport]?.map((location) => (
                            <option key={location} value={location}>{location}</option>
                        ))}
                    </select>


                    <h4 className="decrease-tag">Enter your query</h4>
                    <textarea name="qresponse" placeholder="Please enter your query here(Max 250 words)"
                              value={formData.qresponse}
                              onChange={handleChange} required rows="4" style={{height: 'auto'}}></textarea>

                    <button type="submit" className="submit-btn" style={{marginTop: "10px"}}>Submit</button>
                    <button type="button" className="explore-btn" onClick={() => window.scrollTo(0, 0)}>Explore batches
                        and timings
                    </button>
                </form>
            </section>
        </div>
    );
}

export default QueryForm;

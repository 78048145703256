// FAQ.js
import React, {useState} from 'react';
import './FAQ.css'; // Importing the CSS file for styles

function FAQ() {
    // This could be fetched from an API or defined elsewhere
    const faqs = [
        { question: 'Q1. What will be coach to player ratio?', answer: '\nFor Badminton, Coach to player ratio will be 1:8\n' +
                '\n' +
                'For Swimming, Coach to player ratio will be 1:10' },
        { question: 'Q2. When Summer camp will start and when it will end?', answer: '\nMachaxi Summer Camp will start on April 1, 2024 and will end on May 31, 2024' },
        { question: 'Q3. What are the batches available for Badminton?', answer: '\nFor Badminton there are two batch timings. \n' +
                '1. 9 AM - 10 AM\n' +
                '2. 10 AM - 11 AM\n' +
                '\n' +
                'You can opt for plans based on number of sessions during this camp.' },
        { question: 'Q4. What are the batches available for Swimming?', answer: '\nFor Swimming there are multiple batch time options and there are 3 batches \n' +
                '\n' +
                'Batches are: \n' +
                'Batch 1: April 1 - April 20\n' +
                'Batch 2: April 22 - May 11\n' +
                'Batch 3: May 13 - May 31\n' +
                '\n' +
                'Class Timings: 8 AM - 9 AM, 9 AM - 10 AM, 10 AM - 11 AM, 3 PM - 4 PM, 4 PM - 5 PM\n' +
                '\n' +
                'Apart from Summer camp, we have regular batches in morning and evening\n\n' +
                '*These are tentative timings, kindly explore the plan to know the active timings and plans' },
        { question: 'Q5. Are there any early bird offer?', answer: '\nYes there are early bird discount available till March 20th, Please check our plan for the sport you want to enroll for' },
        { question: 'Q6. What are the sports equipments and attire required?', answer: '\nFor Badminton, Racquet and non-marking badminton shoes are mantodatory\n' +
                '\n' +
                'For Swimming, swim trunks/suits with swimming cap is mandatory. Player may need goggles as well for underwater training' },
        { question: 'Q7. When will the badminton tournament will be held?', answer: '\nBadminton tournament will be held towards end of May' },

    ];

    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleExpand = (index) => {
        setExpandedIndex(index === expandedIndex ? null : index);
    };

    return (
        // <div className="faq-container">
        //     <h2>FAQ</h2>
        //     <ul className="faq-list">
        //         {faqs.map((faq, index) => (
        //             <li key={index} className="faq-item">
        //                 {/*<span className="faq-icon">❓</span>*/}
        //                 <div className="faq-content">
        //                     <h3 className="faq-question">{faq.question}</h3>
        //                     <p className="faq-answer" style={{whiteSpace: 'pre-line'}}>{faq.answer}</p>
        //                 </div>
        //             </li>
        //         ))}
        //     </ul>
        // </div>
        <div className="faq-container">
            <h2>FAQ</h2>
            <ul className="faq-list">
                {faqs.map((faq, index) => (
                    <li key={index} className="faq-item">
                        <div className="faq-content">
                            <div className="faq-question" onClick={() => handleExpand(index)}>
                                {faq.question}
                            </div>
                            {index === expandedIndex && (
                                <p className="faq-answer" style={{whiteSpace: 'pre-line'}}>{faq.answer}</p>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default FAQ;

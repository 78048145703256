import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import machaxilogo from './Machaxi.png'
import styled from 'styled-components';
function EnrollmentForm() {
    ReactPixel.track('Lead', {
        content_name: 'Machaxi Summer camp',
        content_category: 'Form',
    });
    const [formData, setFormData] = useState({
        location: 'Bangalore, Karnataka',
        sport: '',
        center: '',
        plan: '',
        startDate: '',
        parentsName: '',
        mobileNumber: '',
        referralCode: '',
    });

    const [dynamicData, setDynamicData] = useState({
        centers: [],
        plans: [],
        startDates: [],
    });

    const sportsData = {
        Badminton: {
            centers: [
                {
                    name: 'Machaxi Badminton Centre - Marathahalli',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Sumukha - Varthur',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi AJ Sports - Bellandur',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi J Sports - Hoodi',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Ahalya - Near Manyata Tech Park',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Play9 - Whitefield',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Cross Court - Ramamurthy Nagar',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Maagniv - Horamavu',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Pragathi - Horamavu',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Sportexx - Sahakar Nagar',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2999',
                                '16 Sessions ₹3499',
                                '20 Sessions ₹3999',
                                '24 Sessions ₹4499',
                                '30 Sessions ₹5299',
                                '40 Sessions ₹6199'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Ready to Rally - Kasturi Nagar',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2499',
                                '16 Sessions ₹2999',
                                '20 Sessions ₹3499',
                                '24 Sessions ₹3999',
                                '30 Sessions ₹4799',
                                '40 Sessions ₹5699'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2499',
                                '16 Sessions ₹2999',
                                '20 Sessions ₹3499',
                                '24 Sessions ₹3999',
                                '30 Sessions ₹4799',
                                '40 Sessions ₹5699'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Let\'s Play - Akshay Nagar',
                    plans: [
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                '12 Sessions ₹2499',
                                '16 Sessions ₹2999',
                                '20 Sessions ₹3499',
                                '24 Sessions ₹3999',
                                '30 Sessions ₹4799',
                                '40 Sessions ₹5699'
                            ]
                        },
                        {
                            time: '10 am - 11 am',
                            priceDetails: [
                                '12 Sessions ₹2499',
                                '16 Sessions ₹2999',
                                '20 Sessions ₹3499',
                                '24 Sessions ₹3999',
                                '30 Sessions ₹4799',
                                '40 Sessions ₹5699'
                            ]
                        }
                    ]
                }
            ]
        },

        Swimming: {
            centers: [
                {
                    name: 'Machaxi Ahalya - Near Manyata Tech Park',
                    plans: [
                        {
                            time: '7 am - 8 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '8 am - 9 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '3 pm - 4 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '4 pm - 5 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '5 pm - 6 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Sportexx - Sahakar Nagar',
                    plans: [
                        {
                            time: '7 am - 8 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '8 am - 9 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '3 pm - 4 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '4 pm - 5 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '5 pm - 6 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi AJ Sports - Hoodi',
                    plans: [
                        {
                            time: '7 am - 8 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '8 am - 9 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '3 pm - 4 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '4 pm - 5 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        },
                        {
                            time: '5 pm - 6 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3750',
                                'May 20 - June 15 ₹3750'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Milan Sports - G B Palya Electronic City',
                    plans: [
                        {
                            time: '7 am - 8 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3000',
                                'May 20 - June 15 ₹3000'
                            ]
                        },
                        {
                            time: '8 am - 9 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3000',
                                'May 20 - June 15 ₹3000'
                            ]
                        },
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                'May 13 - May 31 ₹3000',
                                'May 20 - June 15 ₹3000'
                            ]
                        },
                        {
                            time: '3 pm - 4 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3000',
                                'May 20 - June 15 ₹3000'
                            ]
                        },
                        {
                            time: '4 pm - 5 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3000',
                                'May 20 - June 15 ₹3000'
                            ]
                        },
                        {
                            time: '5 pm - 6 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹3000',
                                'May 20 - June 15 ₹3000'
                            ]
                        }
                    ]
                },
                {
                    name: 'Machaxi Ready to Rally - Kasturi Nagar',
                    plans: [
                        {
                            time: '7 am - 8 am',
                            priceDetails: [
                                'May 13 - May 31 ₹4500',
                                'May 20 - June 15 ₹4500'
                            ]
                        },
                        {
                            time: '8 am - 9 am',
                            priceDetails: [
                                'May 13 - May 31 ₹4500',
                                'May 20 - June 15 ₹4500'
                            ]
                        },
                        {
                            time: '9 am - 10 am',
                            priceDetails: [
                                'May 13 - May 31 ₹4500',
                                'May 20 - June 15 ₹4500'
                            ]
                        },
                        {
                            time: '3 pm - 4 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹4500',
                                'May 20 - June 15 ₹4500'
                            ]
                        },
                        {
                            time: '4 pm - 5 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹4500',
                                'May 20 - June 15 ₹4500'
                            ]
                        },
                        {
                            time: '5 pm - 6 pm',
                            priceDetails: [
                                'May 13 - May 31 ₹4500',
                                'May 20 - June 15 ₹4500'
                            ]
                        }
                    ]
                }
            ]
        },
        Football: {
            centers: [
                {
                    name: 'Machaxi AJ Sports - Hoodi',
                    plans: [
                        {
                            time: '5 pm - 6:30 pm',
                            priceDetails: [
                                '24 Sessions ₹4000',
                                '40 Sessions ₹8000',
                            ]
                        }
                    ]
                }
            ]
        },
        'Badminton & Swimming': {
            centers: [
                {
                    name: 'Machaxi Ahalya - Near Manyata Tech Park',
                    plans: [
                        {
                            time: '7 am - 8 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '8 am - 9 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },{
                            time: '9 am - 10 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '3 pm - 4 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },{
                            time: '4 pm - 5 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '5 pm - 6 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        }
                    ]
                },{
                    name: 'Machaxi Sportexx - Sahakar Nagar',
                    plans: [
                        {
                            time: '7 am - 8 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '8 am - 9 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },{
                            time: '9 am - 10 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '3 pm - 4 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },{
                            time: '4 pm - 5 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '5 pm - 6 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        }
                    ]
                },{
                    name: 'Machaxi Ready to Rally - Kasturi Nagar',
                    plans: [
                        {
                            time: '7 am - 8 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '8 am - 9 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },{
                            time: '9 am - 10 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '3 pm - 4 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },{
                            time: '4 pm - 5 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '5 pm - 6 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        }
                    ]
                },{
                    name: 'Machaxi Aj Sports - Hoodi',
                    plans: [
                        {
                            time: '7 am - 8 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '8 am - 9 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },{
                            time: '9 am - 10 am',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '3 pm - 4 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },{
                            time: '4 pm - 5 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        },
                        {
                            time: '5 pm - 6 pm',
                            priceDetails: [
                                '28 Sessions ₹5399',
                                '40 Sessions ₹6599',
                            ]
                        }
                    ]
                }
            ]
        }
        // Add more sports if needed
    };

    sportsData.Swimming.centers.forEach(center => {
        center.plans.forEach(plan => {
            if (plan.time === '7 am - 8 am' || plan.time === '4 pm - 5 pm' || plan.time === '5 pm - 6 pm') {
                plan.priceDetails = plan.priceDetails.filter(details => !details.includes('April 8 - April 26'));
            }
        });
    });

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const handleSportChange = (sport) => {
        setFormData({ ...formData, sport, center: '', plan: '', startDate: '' });
        setDynamicData({
            centers: sportsData[sport]?.centers || [],
            plans: [],
            startDates: [],
        });
    };

    const handleCenterChange = (centerName) => {
        const selectedCenter = dynamicData.centers.find(center => center.name === centerName);
        setFormData({ ...formData, center: centerName, plan: '', startDate: '' });
        setDynamicData({ ...dynamicData, plans: selectedCenter?.plans || [], startDates: [] });
    };

    // const handlePlanChange = (planTime) => {
    //     const selectedPlan = dynamicData.plans.find(plan => plan.time === planTime);
    //     setFormData({ ...formData, plan: planTime, startDate: selectedPlan.priceDetails });
    // };

    const handlePlanChange = (planTime) => {
        const selectedPlan = dynamicData.plans.find(plan => plan.time === planTime);
        if (selectedPlan) {
            setFormData({ ...formData, plan: planTime, startDate: '' }); // Clear the startDate when a new plan is selected
            setDynamicData({ ...dynamicData, startDates: selectedPlan.priceDetails || [] }); // Ensure this is always an array
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    function validateMobileNumber(mobileNumber) {
        const regex = /^[6-9]\d{9}$/;
        return regex.test(mobileNumber);
    }

    // let newAmount;
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.sport || !formData.center || !formData.plan || !formData.startDate || !formData.parentsName || !formData.mobileNumber) {
            alert('Please fill out all fields before submitting.');
            return;
        }
        if(formData.referralCode.length>0){
            if (formData.referralCode.length !== 8) {
                alert('Invalid Referral Code.');
                return;
            }
        }
        const priceDetail = formData.startDate;
        const allFourDigitMatches = priceDetail.match(/\d{4}/g);
        const convertedStartDate = allFourDigitMatches ? allFourDigitMatches[allFourDigitMatches.length - 1] : null;
        let originalStartDate;
        if (formData.sport === 'Badminton') {
            const newDetails = formData.startDate;
            originalStartDate = newDetails.split('₹')[0].trim();
        } else if (formData.sport === 'Swimming') {
            originalStartDate = '16 sessions';
        } else{
            const newDetails = formData.startDate;
            originalStartDate = newDetails.split('₹')[0].trim();
        }
        if (!validateMobileNumber(formData.mobileNumber)) {
            alert('Please check entered Mobile number');
            return;
        }
        let validity;
        if (formData.sport === 'Badminton') {
            validity = 'April 1, 2024 - May 31, 2024';
        } else if (formData.sport === 'Swimming') {
            const newValidity = formData.startDate;
            validity = newValidity.split('₹')[0].trim();
        } else {
            validity = 'April 1, 2024 - May 31, 2024';
        }


        const dataToSubmit = {
            ...formData,
            originalStartDate: originalStartDate,
            startDate: convertedStartDate,
            validity: validity
        };

        try {
            let originalStartDate2;
            if (formData.sport === 'Badminton') {
                const newDetails = formData.startDate;
                originalStartDate2 = newDetails.split('₹')[0].trim();
            } else if (formData.sport === 'Swimming') {
                originalStartDate2 = '16 sessions';
            } else{
                const newDetails = formData.startDate;
                originalStartDate2 = newDetails.split('₹')[0].trim();
            }
            const slotResponse = await axios.post('https://admin.machaxi.com/summer-api/slots-check', {
                sport: formData.sport,
                center: formData.center,
                time: formData.plan,
                session_details: originalStartDate2,
                validity: validity
            });
            const slotCount = slotResponse.data.slotCount;
            // alert(slotCount);
            if (formData.sport==='Badminton') {
                if(slotCount<50){
                    try {
                        await axios.post('https://admin.machaxi.com/summer-api/enroll', JSON.stringify(dataToSubmit), {
                            headers: {'Content-Type': 'application/json'},
                        });
                        // console.log(response.data);
                        alert(`Please check and confirm all details\n\nSelected Sport: ${formData.sport}\nSelected Center: ${formData.center}\nSelected Batch Time: ${formData.plan}\nSelected Session Details: ${originalStartDate2}\nPlayer's Name: ${formData.parentsName}\nMobile Number: ${formData.mobileNumber}`);
                        await paymentHandler(e);
                    }catch(err){
                        console.log(err);
                    }
                }
                else{
                    alert("Slot you are trying to enroll for is full, please try a different batch/time slot\n" +
                        "\n" +
                        "We appreciate your cooperation in helping us maintain coach to player ratio!\n" +
                        "\n" +
                        "Thank you")
                }
            }else if(formData.sport==='Swimming') {
                if(slotCount<30){
                    try {
                        await axios.post('https://admin.machaxi.com/summer-api/enroll', JSON.stringify(dataToSubmit), {
                            headers: {'Content-Type': 'application/json'},
                        });
                        // console.log(response.data);
                        alert(`Please Check Enrollment Details\n\nSelected Sport: ${formData.sport}\nSelected Center: ${formData.center}\nSelected Batch Time: ${formData.plan}\nSelected Session Details: ${originalStartDate2}\nPlayer's Name: ${formData.parentsName}\nMobile Number: ${formData.mobileNumber}`);
                        await paymentHandler(e);
                    }catch(err){
                        console.log(err);
                    }
                }else{
                    alert("Slot you are trying to enroll for is full, please try a different batch/time slot\n" +
                        "\n" +
                        "We appreciate your cooperation in helping us maintain coach to player ratio!\n" +
                        "\n" +
                        "Thank you")
                }
            }else {
                if(slotCount<30){
                    try {
                        await axios.post('https://admin.machaxi.com/summer-api/enroll', JSON.stringify(dataToSubmit), {
                            headers: {'Content-Type': 'application/json'},
                        });
                        // console.log(response.data);
                        alert(`Please Check Enrollment Details\n\nSelected Sport: ${formData.sport}\nSelected Center: ${formData.center}\nSelected Batch Time: ${formData.plan}\nSelected Session Details: ${originalStartDate2}\nPlayer's Name: ${formData.parentsName}\nMobile Number: ${formData.mobileNumber}`);
                        await paymentHandler(e);
                    }catch(err){
                        console.log(err);
                    }
                }else{
                    alert("Slot you are trying to enroll for is full, please try a different batch/time slot\n" +
                        "\n" +
                        "We appreciate your cooperation in helping us maintain coach to player ratio!\n" +
                        "\n" +
                        "Thank you")
                }
            }
        } catch (error) {
            console.error('There was an error submitting the form:', error);
            alert('There was an error submitting the form. Please try again.');
        }


        // try {
        //     const response = await axios.post('http://localhost:5000/api/enroll', JSON.stringify(dataToSubmit), {
        //         headers: { 'Content-Type': 'application/json' },
        //     });
        //     console.log(response.data);
        //     alert(`Enrollment Completed! \n\nSelected Data:\nSelected Sport: ${formData.sport}\nSelected Center: ${formData.center}\nSelected Batch Time: ${formData.plan}\nSelected Session Details: ${formData.startDate}\nPlayer's Name: ${formData.parentsName}\nMobile Number: ${formData.mobileNumber}\nSelected Amount: ${convertedStartDate}`);
        // } catch (error) {
        //     alert(`Enrollment Completed! \n\nSelected Data:\nSelected Sport: ${formData.sport}\nSelected Center: ${formData.center}\nSelected Batch Time: ${formData.plan}\nSelected Session Details: ${formData.startDate}\nPlayer's Name: ${formData.parentsName}\nMobile Number: ${formData.mobileNumber}\nSelected Amount: ${convertedStartDate}`);
        //     console.error('There was an error submitting the form:', error);
        //     alert('There was an error submitting the form. Please try again.');
        // }

        // setFormData({
        //     location: 'Bangalore, Karnataka',
        //     sport: '',
        //     center: '',
        //     plan: '',
        //     startDate: '',
        //     parentsName: '',
        //     mobileNumber: '',
        // });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    function ContactInfo({ formData }) {
        if (formData.sport === 'Badminton & Swimming' && formData.center === 'Machaxi Ahalya - Near Manyata Tech Park') {
            return <p>Kindly contact 9355950961 for clarification</p>;
        } else if (formData.sport === 'Badminton & Swimming' && formData.center === 'Machaxi Sportexx - Sahakar Nagar') {
            return <p>Kindly contact 9355082562 for clarification</p>;
        } else if (formData.sport === 'Badminton & Swimming' && formData.center === 'Machaxi Ready to Rally - Kasturi Nagar') {
            return <p>Kindly contact 8068380167 for clarification</p>;
        } else {
            return <p>Kindly contact 8929649609 for clarification</p>;
        }
    }
    const priceDetail = formData.startDate;
    const allFourDigitMatches = priceDetail.match(/\d{4}/g);
    const convertedStartDate = allFourDigitMatches ? allFourDigitMatches[allFourDigitMatches.length - 1] : null;
    const amount = convertedStartDate*100;
    const currency = "INR";
    // const receiptId = "qwsaq1";
    const receiptId = `receipt_${new Date().getTime()}`;
    let originalStartDate3;
    if (formData.sport === 'Badminton') {
        const newDetails = formData.startDate;
        originalStartDate3 = newDetails.split('₹')[0].trim();
    } else if (formData.sport === 'Swimming') {
        originalStartDate3 = '16 sessions';
    } else {
        const newDetails = formData.startDate;
        originalStartDate3 = newDetails.split('₹')[0].trim();
    }

    let validity9;
    if (formData.sport === 'Badminton') {
        validity9 = 'April 1, 2024 - May 31, 2024';
    } else if (formData.sport === 'Swimming') {
        const newValidity = formData.startDate;
        validity9 = newValidity.split('₹')[0].trim();
    } else {
        validity9 = 'April 1, 2024 - May 31, 2024';
    }

    const enrollmentDataString = `SC\nSport: ${formData.sport},\nCenter: ${formData.center},\nBTime: ${formData.plan},\nPName: ${formData.parentsName},\nMobNum: ${formData.mobileNumber},\nSessions: ${originalStartDate3},\nValidity: ${validity9}`;

    const enrollmentDataString2 = `\nSelected Sport: ${formData.sport},\nSelected Center: ${formData.center},\nSelected Batch Time: ${formData.plan},\nPlayer's Name: ${formData.parentsName},\nMobile Number: ${formData.mobileNumber},\nSessions: ${originalStartDate3},\nValidity: ${validity9}`;


    const paymentHandler = async (e) => {
        const response = await fetch("https://admin.machaxi.com/summer-api/order", {
            method: "POST",
            body: JSON.stringify({
                amount,
                currency,
                receipt: receiptId,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        const order = await response.json();
        // console.log(order);
        // alert(order);
        const dataToSubmit = {
            ...formData,
            originalStartDate: originalStartDate3,
            startDate: convertedStartDate,
            validity: validity9
        };
        var options = {
            key: "rzp_live_uh7h7N55m9BHaZ",
            amount,
            currency,
            name: "Machaxi",
            description: enrollmentDataString,
            image: machaxilogo,
            order_id: order.id,
            handler: async function (response) {
                const body = {
                    ...response, ...dataToSubmit, amount
                };

                const validateRes = await fetch(
                    "https://admin.machaxi.com/summer-api/order/validate",
                    {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                const jsonRes = await validateRes.json();
                // console.log(jsonRes);
                if(jsonRes.msg==="success"){
                    alert(`Payment Successfull\n\nPlease take screenshot of this message\n\nOrder Id: ${jsonRes.paymentId},${enrollmentDataString2}`);
                    setFormData({
                        location: 'Bangalore, Karnataka',
                        sport: '',
                        center: '',
                        plan: '',
                        startDate: '',
                        parentsName: '',
                        mobileNumber: '',
                        referralCode: '',
                    });
                }else{
                    alert("Payment Failed");
                }
            },
            prefill: {
                name: formData.parentsName,
                contact: formData.mobileNumber,
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
            alert("Payment Failed, Please try again with another method");
        });
        rzp1.open();
        e.preventDefault();
    };

    return (
        <div className="container">
            <header className="header">
                <img src={machaxilogo} alt="Summer Camp" height={45} width={55}/>
                <h2>Machaxi Summer Camp</h2>
            </header>
            <BannerSection>
                <Slider {...settings}>
                    <div><img src="/Banner1.jpeg" alt="Banner 1" /></div>
                    <div><img src="/Banner2.jpeg" alt="Banner 2" /></div>
                    <div><img src="/Banner3.jpg" alt="Banner 3" /></div>
                </Slider>
            </BannerSection>
            <main className="main">
                <h2 className="title">Enroll for Summer Camp</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Select Sports</label>
                        <div className="button-group">
                            {Object.keys(sportsData).map(sport => (
                                <button
                                    key={sport}
                                    type="button"
                                    onClick={() => handleSportChange(sport)}
                                    className={`button ${formData.sport === sport ? 'selected' : ''}`}
                                >
                                    {sport}
                                </button>
                            ))}
                        </div>
                    </div>
                    {formData.sport && (
                        <div className="form-group">
                            <label>Select Centre</label>
                            <div className="dropdown">
                                <select value={formData.center} onChange={(e) => handleCenterChange(e.target.value)}>
                                    <option value="">Select Center</option>
                                    {dynamicData.centers.map(center => (
                                        <option key={center.name} value={center.name}>{center.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
                    {formData.center && (
                        <div className="form-group">
                            <label>
                                {
                                    (formData.sport === 'Badminton' || formData.sport === 'Football' || formData.sport === 'Badminton & Swimming')
                                        ? 'Select Batch Time (Monday - Friday)'
                                        : (
                                            (formData.sport === 'Swimming' && formData.center === 'Machaxi Ready to Rally - Kasturi Nagar')
                                                ? 'Select Batch Time (Monday - Saturday)'
                                                : 'Select Batch Time (Monday - Saturday)'
                                        )
                                }
                            </label>
                            <ul className="list">
                                {dynamicData.plans.map(plan => (
                                    <li key={plan.time}>
                                        <button
                                            type="button"
                                            onClick={() => handlePlanChange(plan.time)}
                                            className={`button ${formData.plan === plan.time ? 'selected' : ''}`}
                                        >
                                            {plan.time}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <label>
                                {
                                    (formData.sport === 'Badminton & Swimming')
                                        ? '𝐏𝐥𝐞𝐚𝐬𝐞 𝐧𝐨𝐭𝐞: Above mentioned time slots are for Swimming Summer Camp. Badminton Summer camp timing is 𝟗 𝐀𝐌 - 𝟏𝟎 𝐀𝐌 and 𝟏𝟎 𝐀𝐌 - 𝟏𝟏 𝐀𝐌. You may chose either of these slots for badminton after enrollment.'
                                        : ''
                                }
                            </label>
                            <label>
                                {
                                    (formData.sport === 'Badminton & Swimming')
                                        ? <ContactInfo formData={formData}/>
                                        : ''
                                }
                            </label>
                        </div>
                    )}

                    {formData.plan && (
                        <div className="form-group">
                            <label>Select Plan</label>
                            <h3 style={{color: "blue", textAlign: 'center'}}>
                                {formData.sport === 'Badminton' ? '' : ''}
                            </h3>
                            <p style={{textAlign: 'center'}}>
                                {/*{formData.sport === 'Badminton' ? 'Valid from April 1, 2024 - May 31, 2024' : 'Number of Sessions: 16'}*/}
                                {
                                    (formData.sport === 'Badminton' || formData.sport === 'Football')
                                        ? 'Valid from April 1, 2024 - May 31, 2024'
                                        : (formData.sport === 'Swimming'
                                                ? 'Number of Sessions: 16'
                                                : ''
                                        )
                                }
                            </p>
                            <div className="button-group">
                                {dynamicData.startDates && dynamicData.startDates.map(date => (
                                    <button
                                        key={date}
                                        type="button"
                                        onClick={() => handleInputChange({target: {name: 'startDate', value: date}})}
                                        className={`button ${formData.startDate === date ? 'selected' : ''}`}
                                    >
                                        {date.split('₹').map((part, index) => (
                                            index === 1 ? <><span className="whitespace">{'    '}</span>₹{part}</> : part
                                        ))}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}

                    {formData.startDate && <div>
                        <section className="query-section">
                            <form className="query-form" onSubmit={handleSubmit}>
                                <h4 className="decrease-tag">Player's Name</h4>
                                <input type="text" name="parentsName" placeholder="Enter player's name"
                                       value={formData.parentsName} onChange={handleChange} required/>

                                <h4 className="decrease-tag">Mobile Number</h4>
                                <input type="tel" name="mobileNumber" placeholder="Enter mobile number"
                                       value={formData.mobileNumber} onChange={handleChange} required pattern="\d*"
                                       title="Mobile number should contain digits only."/>
                                <h4 className="decrease-tag">Referral Code (Optional)</h4>
                                <input type="text" name="referralCode" placeholder="Enter referral code"
                                       value={formData.referralCode} onChange={handleChange} required pattern="^\d{8}$"
                                       title="Referral Code must be exactly 8 digits."/>

                            </form>
                        </section>
                        <button type="submit" className="submit-button">Pay Now & Enroll</button>
                    </div>}

                </form>
            </main>
        </div>
    );
}

export default EnrollmentForm;


const BannerSection = styled.section`
    text-align: center;
    margin: 3rem 1rem;

    @media (max-width: 768px) {
        margin: 2rem 0.5rem;
    }

    img {
        width: 100%;
        height: auto;
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }
`;
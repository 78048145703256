import React from 'react';
import './footer.css'

function Footer() {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-section">
                    <h3 style={{marginBottom: "20px"}}>Contact Us</h3>
                    <p style={{marginBottom: "20px"}}>Email: <a href="mailto:hello@machaxi.com">hello@machaxi.com</a>
                    </p>
                    <p style={{marginBottom: "20px"}}>WhatsApp: <a
                        href="https://api.whatsapp.com/send/?phone=9663979929&text=Hello+Machaxi&type=phone_number&app_absent=0"
                        target="_blank" rel="noreferrer">Click here to contact us</a></p>
                    {/*<p style={{marginBottom: "20px"}}>Machaxi Sports Shop: <a href="tel:+917204986281">+91*/}
                    {/*    7204986281</a></p>*/}
                    {/*<p style={{marginBottom: "20px"}}>Address: Machaxi Badminton Centre, Munnekollal, 104/3A,*/}
                    {/*    Munnekolala Main Road, near Akbar*/}
                    {/*    Garrage, Gandhi Nagar, Munnekollal, Bengaluru, Karnataka 560037 /!*(<a*/}
                    {/*        href="https://maps.google.com/maps?q=XP34%2B6C+Bengaluru%2C+Karnataka" target="_blank"*/}
                    {/*        rel="noreferrer">View on Map</a>)*!/</p>*/}

                    {/*<p style={{marginBottom: "15px"}}>Address: Machaxi Badminton Centre, Munnekollal, 104/3A,*/}
                    {/*    Munnekolala Main Road, near Akbar Garrage, Gandhi Nagar, Munnekollal, Bengaluru, Karnataka*/}
                    {/*    560037 (<a href="https://maps.google.com/maps?q=XP34%2B6C+Bengaluru%2C+Karnataka"*/}
                    {/*               target="_blank">View on Map</a>)</p>*/}
                    <h3 style={{marginBottom: "10px", marginTop: "10px"}}>Machaxi Centres</h3>
                    <p>Machaxi Sumukha Badminton Centre:</p> <p style={{marginBottom: "10px"}}>+91 9355082561</p>
                    <p>Machaxi Badminton Centre Munnekollal:</p> <p style={{marginBottom: "10px"}}>+91 9355082551</p>
                    <p>Machaxi AJ Badminton Centre:</p> <p style={{marginBottom: "10px"}}>+91 9355932124</p>
                    <p>Machaxi J Sports:</p> <p style={{marginBottom: "10px"}}>+91 9355082562</p>
                    <p>Machaxi Ahalya Sports Centre:</p> <p style={{marginBottom: "10px"}}>+91 9355950961</p>
                    <p>Machaxi Play9 Sports Centre:</p> <p style={{marginBottom: "10px"}}>+91 9355082585</p>
                    <p>Machaxi Cross Court Badminton Centre:</p> <p style={{marginBottom: "10px"}}>+91 9355932130</p>
                    <p>Machaxi Maagniv Badminton Centre:</p> <p style={{marginBottom: "10px"}}>+91 9873041839</p>
                    <p>Machaxi Pragathi Badminton Centre:</p> <p style={{marginBottom: "10px"}}>+91 8588851412</p>
                    <p>Machaxi Milan Sports Centre:</p> <p style={{marginBottom: "10px"}}>+91 9873041135</p>
                    <p>Machaxi Sportexx Sports Centre:</p> <p style={{marginBottom: "10px"}}>+91 9355082601</p>
                    <p>Machaxi Ready to Rally Sports Centre:</p> <p style={{marginBottom: "10px"}}>+91 8929649609</p>
                    <p>Machaxi Aj Hoodi Sports Centre:</p> <p style={{marginBottom: "10px"}}>+91 9355082562</p>
                    <p>Machaxi Let's Play Badminton Centre:</p> <p style={{marginBottom: "10px"}}>+91 9663979929</p>


                    <div className={"bg-white"}>
                        <ul style={{listStyle: "none", padding: 0, margin: 0}}>
                            <li style={{display: "inline-block", marginRight: "20px"}}>
                                <a href="https://wa.me/9663979929?text=Hello%20Machaxi" target="_blank"
                                   rel="noreferrer">
                                    <img width="18" height="20" src={"/whatsapp.png"} alt="whatsapp"
                                         style={{filter: "invert(100%)", backgroundColor: "white"}}/>
                                </a>
                            </li>
                            <li style={{display: "inline-block", marginRight: "20px"}}>
                                <a href="https://www.facebook.com/machaxi" target="_blank" rel="noreferrer">
                                    <img width="17" height="20" src={"/facebook.png"} alt="facebook"
                                         style={{filter: "invert(100%)", backgroundColor: "white"}}/>
                                </a>
                            </li>
                            <li style={{display: "inline-block", marginRight: "20px"}}>
                                <a href="https://twitter.com/hello_machaxi" target="_blank" rel="noreferrer">
                                    <img width="16" height="17" src={"/twitter.png"} alt="twitter"
                                         style={{filter: "invert(100%)", backgroundColor: "white"}}/>
                                </a>
                            </li>
                            <li style={{display: "inline-block", marginRight: "20px"}}>
                                <a href="https://www.instagram.com/machaxi/?igshid=all0icyhdn1b" target="_blank"
                                   rel="noreferrer">
                                    <img width="17" height="20" src={"/instagram.png"} alt="instagram"
                                         style={{filter: "invert(100%)", backgroundColor: "white"}}/>
                                </a>
                            </li>
                            <li style={{display: "inline-block"}}>
                                <a href="https://www.youtube.com/channel/UCjfWnIYVxpCFfJxvwGGE5sA" target="_blank"
                                   rel="noreferrer">
                                    <img width="20" height="20" src={"/youtube.png"} alt="youtube"
                                         style={{filter: "invert(100%)", backgroundColor: "white"}}/>
                                </a>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
        </footer>
    );
}

export default Footer;
